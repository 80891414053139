<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        Balance the following redox reaction in an acidic solution. Note that both
        <chemical-latex content="H2O" />
        and
        <chemical-latex content="H+" />
        are included on both sides of the reaction. Zeroes (0) should be placed in those input
        fields if they don't appear on that side of the reaction.
      </p>

      <p class="mb-2 pb-0">
        <v-text-field
          v-model="inputs.agPlus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="Ag+(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.so3"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="SO3^2-(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.leftWater"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H2O(l) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.leftHPlus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H+(aq)" class="mb-n6" />
      </p>

      <p class="mt-0 pt-0 pl-14 mb-0">
        <stemble-latex content="$\ce{->} \,\,\,$" class="mb-n6" />

        <v-text-field
          v-model="inputs.agSolid"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="Ag(s) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.so4"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="SO4^2-(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.rightWater"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H2O(l) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.rightHPlus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H+(aq)" class="mb-n6" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question72',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        agPlus: 0,
        so3: 0,
        leftWater: 0,
        leftHPlus: 0,
        agSolid: 0,
        so4: 0,
        rightWater: 0,
        rightHPlus: 0,
      },
    };
  },
};
</script>
